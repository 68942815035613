/* line 3, root/css/support-transcription-api-page.less */
#support-transcription-api-page .news {
  width: 100%;
}
/* line 5, root/css/support-transcription-api-page.less */
#support-transcription-api-page .github-card {
  border-radius: 5px;
  padding: 8px 8px 0;
  background: #fff;
  color: #555;
  position: relative;
  border: 1px solid #6b6b6b;
  width: 100%;
  min-width: 400px;
  display: inline-block;
  margin: 1em;
  padding: .5em;
}
/* line 18, root/css/support-transcription-api-page.less */
#support-transcription-api-page .github-card a {
  text-decoration: none;
  color: #4183c4;
  outline: 0;
}
/* line 23, root/css/support-transcription-api-page.less */
#support-transcription-api-page .github-card a:hover {
  text-decoration: underline;
}
/* line 26, root/css/support-transcription-api-page.less */
#support-transcription-api-page .github-card .header {
  position: relative;
}
/* line 29, root/css/support-transcription-api-page.less */
#support-transcription-api-page .github-card .button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px 4px 7px;
  color: #555;
  text-shadow: 0 1px 0 #fff;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  background-color: #e6e6e6;
  background-image: -webkit-linear-gradient(#fafafa, #eaeaea);
  background-image: -moz-linear-gradient(#fafafa, #eaeaea);
  background-image: -ms-linear-gradient(#fafafa, #eaeaea);
  background-image: linear-gradient(#fafafa, #eaeaea);
}
/* line 47, root/css/support-transcription-api-page.less */
#support-transcription-api-page .github-card .button:hover {
  color: #fff;
  text-decoration: none;
  background-color: #3072b3;
  background-image: -webkit-linear-gradient(#599bdc, #3072b3);
  background-image: -moz-linear-gradient(#599bdc, #3072b3);
  background-image: -ms-linear-gradient(#599bdc, #3072b3);
  background-image: linear-gradient(#599bdc, #3072b3);
  border-color: #518cc6 #518cc6 #2a65a0;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
/* line 59, root/css/support-transcription-api-page.less */
#support-transcription-api-page .repo-card .ghheader {
  padding: 3px 0 4px 57px;
}
/* line 62, root/css/support-transcription-api-page.less */
#support-transcription-api-page .repo-card .avatar,
#support-transcription-api-page .repo-card .avatar img {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 4px;
}
/* line 72, root/css/support-transcription-api-page.less */
#support-transcription-api-page .repo-card .ghheader a {
  color: #707070;
}
/* line 75, root/css/support-transcription-api-page.less */
#support-transcription-api-page .repo-card .ghheader strong {
  display: block;
  font-size: 18px;
  line-height: 1.4;
}
/* line 80, root/css/support-transcription-api-page.less */
#support-transcription-api-page .repo-card .ghheader strong a {
  color: #292f33;
}
/* line 83, root/css/support-transcription-api-page.less */
#support-transcription-api-page .repo-card .ghheader sup {
  font-size: 10px;
  margin-left: 3px;
  color: #797979;
}
/* line 88, root/css/support-transcription-api-page.less */
#support-transcription-api-page .repo-card .ghcontent {
  padding: 6px 0 10px;
}
/* line 91, root/css/support-transcription-api-page.less */
#support-transcription-api-page .repo-card .ghcontent p {
  margin: 0 5px 0 0;
  font: 18px/24px Georgia, "Times New Roman", Palatino, serif;
  overflow: hidden;
  clear: both;
  word-wrap: break-word;
}
/* line 98, root/css/support-transcription-api-page.less */
#support-transcription-api-page .repo-card .ghfooter {
  border-top: 1px solid #eee;
  padding: 8px 0 6px;
}
/* line 102, root/css/support-transcription-api-page.less */
#support-transcription-api-page .repo-card .status {
  font-size: 10px;
  padding-right: 10px;
  text-transform: uppercase;
}
/* line 107, root/css/support-transcription-api-page.less */
#support-transcription-api-page .repo-card .status strong {
  font-size: 12px;
  padding-right: 5px;
}
