<script>
  window.process = { env: "development" };
  import "bulma/css/bulma.css";
  import "@fortawesome/fontawesome-free/css/all.css";
  import "./global.css";

  //prosemirror-esque
  import {
    createTranscriptionEditor,
    toggleSpeaker,
    insertNodeAtEnd,
    insertName
  } from "./CWEditor.js";
  import { Schema } from "prosemirror-model";
  import ProsemirrorEditor from "prosemirror-svelte";
  import { Plugin, PluginKey } from 'prosemirror-state';
  import { toPlainText, toJSON } from "prosemirror-svelte/state";
  import { suggestionsPlugin } from "./suggestions.js";
  import { saveRetrieveDocPlugin } from "./saveRetrieveDocPlugin.js";

  //svelty
  import EditorMenu from "./EditorMenu.svelte";
  import { suggestions } from "./store.js";
  import { onMount } from "svelte";

  let focusEditor, editor, editorView;
  let editorState;
  let time = 0;
  let duration;
  let paused = true;
  let audioElement;
  let playbackRate = 1;
  let transcript = "";
  let notPopulated = true;
  let showInsert = true;

  export let populate;
  export let content;
  export let instructions; 

  export let audio;
  export let customer;
  export let assignmentId;
  export let notes;
  export let title;
  export let objid;
  export let verbatim;
  export let nameslist;



	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();



  let names;
  let classNames;

  if (nameslist) {
    names = nameslist.split("|");
  }

  let previewOnly = true;

  if (assignmentId 
    && assignmentId != "ASSIGNMENT_ID_NOT_AVAILABLE") {
    previewOnly = false;
    classNames = "pm";
  }else{
    classNames = "pm previewOnly";
  }
  console.log(classNames);

  $: if (audioElement) {
    playbackRate = audioElement.playbackRate;
  }

  /**
   * Move the audio to match time recorded in a
   * timed element onm apple click
   */
  let clickToTime = function(e) {
    //     console.log("click", e.target);
    if (e.target.classList.contains("timed")) {
      console.log("is a timed", e.target.dataset.starttime);
      if (e.target.dataset.starttime) {
        let newTime = e.target.dataset.starttime / 1000;

        if (e.ctrlKey || e.metaKey) {
          time = newTime;
          console.log("With alt, do something...", e.target);
          return 1;
        }

        if (e && (e.which == 2 || e.button == 4)) {
          time = newTime;
          console.log("middleclicked");
        }
      }
    }
  };

  document.addEventListener("click", clickToTime);

  /**
   * Bind the editorMenu insert button
   * to the prosemirror command insertNodeAtEnd
   * with the parsed content supplied to the app
   */
  function doInsertCommand() {
    if (notPopulated) {
      insertNodeAtEnd(
        editorView.state,
        editorView.dispatch,
        editorView,
        content
      );
      notPopulated = false;
    }
  }

  /**
   * Bind the toggleSpeaker insert button
   * to the command
   *
   * */

  function doInsertSpeaker(event) {
    console.log("in outer doInsertSpeaker", event);
    insertName(
      editorView.state,
      editorView.dispatch,
      editorView,
      event.detail.insertSpeaker
    );
  }

  /**
   * Bind the toggleSpeaker insert button
   * to the command
   *
   * */

  function doToggleSpeaker() {
    let newSpeaker = toggleSpeaker(editorView.state, editorView.dispatch);
    if (newSpeaker) {
      //        console.log("newSpeaker = ",newSpeaker);
      names = [...names, newSpeaker];
    }
  }

  if (customer) {
    console.log(`customer ${customer}`);
    editorState = createTranscriptionEditor(content, []);
    content.parentNode.removeChild(content); // need to ax this for the other stuff to work
  } else {
    //TODO: set the list to this array

    //JSON.stringify(dataArray)
    //    suggestionList  = dataArray;
    //   suggestions.set(dataArray);

    let storageKey  =   objid;
    let localstPlugin =  saveRetrieveDocPlugin(objid) ;
    console.log("localstPlugin". localstPlugin);
    // let localstPlugin =  new  Plugin(
    //   {
    //     key: new PluginKey('localst'),
    //     state: {
    //       init(config, state) {
    //         const strContent = localStorage.getItem('te2-content-' + storageKey);
    //         if (strContent) {
    //           state.doc = state.schema.nodeFromJSON(JSON.parse(strContent));
    //         }
    //       },
    //       apply(tr,prev) {
    //         if (tr.docChanged) {
    //           localStorage.setItem('te2-content' + storageKey, JSON.stringify(tr.doc.toJSON()));
    //         }
    //       },
    //     }
    //   }
      
    //   );
    const plugins = [
      /* OTHER PLUGINS HERE */
      //localstPlugin
      // autosaves and loads content for the page based on the objid
    ];
    if (!populate) {
      // populate, indicates we are not really an autocomplete editor
      // and thus DO NOT NEED Suggestions

      let allSpanData = content.querySelectorAll("#cw-content p span");

      let dataArray = Array.from(allSpanData).map(elem => {
        let ds = Object.assign({}, elem.dataset);
        ds.text = elem.textContent;
        ds.class = elem.className;
        return ds;
      });
      const suggestPlugin = suggestionsPlugin({
        debug: false,
        suggestionsArray: dataArray,
        onExit(args) {
          console.log("stop", args);
          return false;
        }
      });
      plugins.push(suggestPlugin);
    }

    editorState = createTranscriptionEditor(null, plugins);
  }
  //console.log(editorState);

  // create the initial editor state

  function handleChange(event) {
    // get the new editor state from event.detail
    //editorState = event.detail.editorState;
    transcript = editor.innerHTML.replace(/\<suggestion.*\<\/suggestion\>/, "");
   	dispatch('textchange', {
      transcript: transcript,
      editorState: editorState
		});
    console.log("editorChange emitted textchange", event.detail);
  }

  let active;

  // Another UI?
  // maybe wholoe line based?
  // Or alternatively just show when the audio stops?

  let lastActive;
  function updateActive(activeStart) {
    if (lastActive == activeStart) {
      return;
    }
    lastActive = activeStart;
    let newA = document.querySelector(
      `.ProseMirror span.timed[data-starttime="${activeStart}"]`
    );
    //     console.log("updateActive", activeStart,`.ProseMirror span.timed[data-starttime="${activeStart}"]`, newA );

    var x = document.querySelectorAll(".timed.active");
    var i;
    var alreadyActive = 0;
    for (i = 0; i < x.length; i++) {
      if (!(x[i] === newA)) {
        console.log("removing Active", x[i], newA);
        x[i].classList.remove("active");
      } else {
        alreadyActive = 1;
      }
    }

    if (newA && !alreadyActive) {
      console.log("adding Active", newA);
      newA.classList.add("active");
    }
  }

  // highlight was only for customers for some reason,
  // made availabel to workers Mon Jan 18 09:59:13 PST 2021
  $: if (time) {
    // start array is an array of start times in the elements
    active = window.startArray.find(element => element > time * 1000);
    updateActive(active);

    //    focusEditor()
  }

  // log the text content of the editor state, just for fun
  // $: console.log("sdtate",toJSON(editorState));

  // $: console.log(time); // current time of the audio

  onMount(() => {
    if (populate) {
      doInsertCommand();
      showInsert = false;
    }


    if (previewOnly) {
      let el = document.getElementsByClassName("pm")[0];
      el.removeAttribute("contenteditable");
      el.classList.add("previewOnly");
      //TODO Dump text?
    } else {
      focusEditor();
    }
  });
  console.log(editor);

  function handleKeydown(event) {
    event = event || window.event;
    console.log("Svelte keydown", event);
    if (event.metaKey) {
      //console.log("Sveltemeta",event.altKey,  event.key)

      if ((event.altKey && event.keyCode == 32) || event.shiftKey) {
        //console.log("Svelte meta option space")
        if (paused) {
          paused = false;
        } else {
          paused = true;
        }
      }
    }

    if (event.ctrlKey) {
      if (event.keyCode == 32) {
        //console.log("Svelte meta option space")
        if (paused) {
          paused = false;
        } else {
          paused = true;
        }
      } else if (event.key == "j") {
        time = time - 5;
      } else if (event.key == "k") {
        time = time + 5;
      } else if (event.key == "J") {
        //     console.log(audio.buffered)

        audioElement.playbackRate -= 0.25;
        if (audioElement.playbackRate <= 0.5) {
          audioElement.playbackRate = 0.5;
        }
      } else if (event.key == "K") {
        //     console.log(audio.buffered)
        audioElement.playbackRate += 0.25;
        if (audioElement.playbackRate >= 5.0) {
          audioElement.playbackRate = 5.0;
        }
      } else if (event.key == "h") {
        console.log("show help element");
      } else if (event.key == "i") {
        console.log("show info element");
      }
    }
  }

  console.log("show editor menu", audioElement, editor);
</script>

<style>
/* Version: {version} - {date} */

audio {
    margin-bottom: -2px;
    border-radius: 4px 4px 0 0;
    width: 80%;

    min-width: 100px;
    clear: both;
    display: inline-block;
  }

  audio::-webkit-media-controls-panel {
    border-radius: 4px 4px 0 0;
  }

  .field.has-addons.is-centered {
    justify-content: center;
  }

  main {
    height: 100%;
    display: flex;
    flex-flow: column;
    line-height: 1;

    text-align: center;
    padding: 1em;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

  :global(.noscroll) {
    overflow: hidden;
  }
  :global(.pm) {
    box-sizing: border-box;
    width: 80%;
    border: 1px dashed rgba(242, 181, 47, 0.9);
    margin: auto;
    text-align: left;
    padding: 0px 10px;
    flex-grow: 1;
    border-radius: 0 0 4px 4px;

    border-top-color: #dbdbdb;
    border-top-style: solid;
    overflow: scroll;
  }
  :global(.editor-menu) {
    width: 80%;
  }
  :global(.previewOnly) {
    cursor: not-allowed;
  }


</style>

<svelte:window on:keydown={handleKeydown} />

<main>
  <div class="intro">
    <audio
      src={audio}
      type="audio/mp3"
      id="player"
      preload="auto"
      controls
      bind:currentTime={time}
      bind:duration
      bind:paused
      bind:this={audioElement}>
      Not Supported
    </audio>
    {#if audioElement && editor}
      <EditorMenu
        bind:playbackRate={audioElement.playbackRate}
        {previewOnly}
        {assignmentId}
        bind:transcript
        {notes}
        {title}
        {objid}
        {verbatim}
        {instructions}
        bind:names
        on:insertSpeaker={doInsertSpeaker}
        on:toggleSpeaker={doToggleSpeaker}
        on:insert={doInsertCommand}
        bind:activeInsert={notPopulated}
        {showInsert} />
    {/if}
  </div>

  <ProsemirrorEditor
    className={classNames}
    bind:focus={focusEditor}
    bind:editor
    bind:view={editorView}
    placeholder=""
    {editorState}
    on:change={handleChange} />
</main>
