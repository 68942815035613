
<script>


import './global.css'


export let playbackRate;
export let previewOnly;
export let assignmentId;
export let transcript;
export let showInsert;
export let activeInsert;
export let customer;


export let notes;
export let title;
export let objid;
export let verbatim;
export let names;
export let instructions;

let submitting = 0;

let showInstructions=previewOnly && instructions;
let showKeys=false;
let showSubmit=false;
let workform;

if (playbackRate == null){
playbackRate    = 1;
}

if (showInsert == null){
showInsert    = 1;
}



let showPlaybackOptions=false;


	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

 


	function doInsert() {
    console.log("insert emitted") 
		dispatch('insert', {
			insert: true
		});
	}


	function doInsertSpeaker(name) {
    console.log("insertSpeaker emitted") 
		dispatch('insertSpeaker', {
			insertSpeaker: name
		});
	}



	function doToggleSpeaker() {
    console.warn("toggle speaker emitted") 
		dispatch('toggleSpeaker', {
			toggleSpeaker:  true
		});
	}



let submitJob = function (){
    console.log("submitting ", transcript) 
    if (!previewOnly){
        submitting = 1;
        workform.submit()
    }else{
        console.log("can not submit in preview mode")

    }

}





</script>
<style>
p.control {
margin-block-start: 0em;
margin-block-end: 0em;
}
.modal-content {background-color: white;   text-align: left;;}
.editor-menu {display: inline-flex; justify-content: space-between;border-right: 1px solid rgb(219, 219, 219)}
.editor-menu button {border-radius: 0px;}
.modal-content.card {box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);color: #4a4a4a; border-radius: 4px;}
.buttons .button{ margin-bottom: 0; border-bottom-width: 0px}

a.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}


.field.has-addons {

    display: flex;
    justify-content: flex-start;
    margin: 0;

}
.hidden{
    display: none;
}

</style>

<div class="editor-menu"  >
    <div class="field has-addons is-left buttons menu-part">
        <p class="control">
            <button class="button" on:click={doToggleSpeaker}>
                <span class="icon is-small">
                    <i class="fa fa-male" aria-hidden="true"></i> 
                </span>
                <span class="is-hidden-mobile">New Speaker</span>
            </button>
        </p>

        {#if names}

   <div class="dropdown is-hoverable">
  <div class="dropdown-trigger">
    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu4">
    <span class="icon is-small">
                    <i class="fa fa-male" aria-hidden="true"></i> 
                </span> 
      <span>Insert Speaker</span>
      <span class="icon is-small">
        <i class="fas fa-angle-down" aria-hidden="true"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu" id="dropdown-menu4" role="menu">
    <div class="dropdown-content">

    {#each names as name}
      <div class="dropdown-item">
       <a  on:click={() => doInsertSpeaker(name)} href="#" class="dropdown-item">
        {name} 
      </a>        
      </div>
    {/each}


    </div>
  </div>
</div>
        {/if}

        {#if showInsert}
        <p class="control">

            <button class="button"  on:click={doInsert}  disabled={!activeInsert} 
            >
                <span class="icon is-small">
                    <i class="fas fa-align-left"></i>
                </span>
                <span class="is-hidden-mobile">Insert Transcript</span>
            </button>
        </p>
        {/if}



        <p class="control">
            <button class="button" on:click={e => showKeys=true}>
                <span class="icon is-small">
                    <i class="far fa-keyboard"></i>
                </span>
                <span class="is-hidden-mobile">Keys</span>
            </button>
        </p>
        {#if instructions}
        <p class="control">
            <button class="button"  on:click={e => showInstructions=true}>
                <span class="icon is-small">
                    <i class="far fa-question-circle"></i>
                </span>
                <span class="is-hidden-mobile">Instructions</span>
            </button>
        </p>
        {/if}

        <p class="control">
            <button class="button"  on:click={e => showSubmit=true}>
                <span class="icon is-small">
                    <i class="fas fa-file-export"></i>
                </span>
                <span class="is-hidden-mobile">Submit Job</span>
            </button>
        </p>



    </div>

    <div class="field has-addons is-right buttons  menu-part">
        <div class="control">
  <div class="dropdown" class:is-active={showPlaybackOptions}>
  <div class="dropdown-trigger">
    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu"  on:click={e => showPlaybackOptions=true}>
      <span><span class="is-hidden-touch">Speed </span>{playbackRate * 100}%</span>
      <span class="icon is-small">
        <i class="fas fa-angle-down" aria-hidden="true"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu" id="dropdown-menu" role="menu">
    <div class="dropdown-content">
      <a href="#" class="dropdown-item" on:click={e => {  showPlaybackOptions= false; playbackRate  = 0.5; } }>
        50%
      </a>
       <a href="#" class="dropdown-item" on:click={e => {  showPlaybackOptions= false; playbackRate  = 0.75; } }>
        75%
      </a>
      <a href="#" class="dropdown-item is-active" on:click={e => {  showPlaybackOptions= false; playbackRate  = 1; } }>
        100% 
      </a>
      <a href="#" class="dropdown-item" on:click={e => {  showPlaybackOptions= false; playbackRate  = 1.25; } }>
        125%
      </a>
    </div>
  </div>
</div>
        </div>
    </div>



</div>


<div id="keys-modal" class="modal" class:is-active={showKeys}>
    <div class="modal-background"></div>
    <div class="modal-content box">
        <div class="">
        

            <h2 class="title"> Keyboard Shortcuts</h2>
            <table class="table is-fullwidth  is-striped is-hoverable" >
            <thead>
            <tr><th>Key</th><th>Command</th></tr>
            </thead>
                 <tr><th>⌘ ^ SPACE</th><td>Play/Pause audio OR</td> </tr> 
                 <tr><th>⌘ SHIFT</th><td>Play/Pause audio</td> </tr> 
                <tr><th>⌃ k</th><td>Skip Forward</td> </tr>
                <tr><th>⌃ j</th><td>Skip Backward</td>       </tr>
                <tr><th>⌃ ⇧ K</th><td>Faster Playback</td></tr>
                <tr><th>⌃ ⇧ J</th><td>Slower Playback</td></tr>
                <tr><th>⌃ b</th><td>Toggle Speaker labeling</td></tr>
                <!-- <tr><th>⌃ h</th><td>Keyboard Help</td></tr>
                <tr><th>⌃ i</th><td>Instructions</td></tr>
                -->

       

            </table>
        </div>  
    </div>
    <button class="modal-close is-large" aria-label="close" on:click={e => showKeys=false}></button>
</div>



<div id="keys-modal" class="modal" class:is-active={showInstructions}>
    <div class="modal-background"></div>
    <div class="modal-content box is-fluid">
    <div id="instructions" >


{@html instructions}
{#if  customer }
-<!-- empty  -->  
 
-{:else}
<p>Do not work outside the browser and paste text in. Instead, click the spot you need to fix and type there. </p>


<hr/>

<!-- Save your work periodically by clicking the <b>Save</b> icon -->

    <div class="about_this_job">
      <h3 class="subtitle is-4" >About This Job </h3>
      <table class="table is-fullwidth">

        <tr><th>Title:</th>        <td><i>{title}</i></td></tr>
       <tr> <th>ID:</th> <td>{objid}</td></tr>
       <tr> <th>Style:</th>   
            {#if verbatim }
             <td>Verbatim <a href="https://turkerdocs.castingwords.com/documentation/styleguide/Mainstyleguide.html#SG5" target="new">(more info)</a></td>
            {:else}            
             <td>Standard <a href="https://turkerdocs.castingwords.com/documentation/styleguide/Mainstyleguide.html" target="new">(style guide)</a></td>
           {/if}
      </tr>
        
      </table>

        <div id="customernotes">
            <h3 class="subtitle is-5">Notes from customer and/or CastingWords</h3>    
            {#if notes }
              <pre class="cwnotesheader">{notes}</pre>   
            {:else}         
            <p class="cwnotesheader">The customer left no special instructions for this transcription.</p>   
            {/if}  
        </div>

    </div>

{/if}

<div>
<h3 class="subtitle is-4">Controls</h3>
 <table class="table is-fullwidth">
<tr><td><i class="fa fa-male" aria-hidden="true"></i>  </td><td>  Highlight text &amp; click this button to create a speaker label.</td></tr>
<!-- <tr><td><i class="fa fa-save" aria-hidden="true"></i></td><td> Saves your work. Does NOT submit the job.</td>  </tr> -->
<!-- <tr><td><i class="fa fa-search" aria-hidden="true"></i></td><td>Opens the find/replace tool.</td> </tr> -->
<tr><td><i class="fa fa-hand-pointer" aria-hidden="true"></i></td> <td> Apple or Ctl Click on a brown timed word to seek to that spot in audio</td>  </tr>
</table>
</div>
</div>








    </div>
    <button class="modal-close is-large" aria-label="close" on:click={e => showInstructions=false}></button>
</div>




<div id="keys-modal" class="modal" class:is-active={showSubmit}>
    <div class="modal-background"></div>
    <div class="modal-content card">
 
<div class="">
  <header class="card-header">
    <p class="card-header-title">
      Submit Job<span class:hidden={!previewOnly}>&nbsp;- Preview mode - VIEW ONLY 
    </p>

  </header>
  <div class="card-content">
    <div class="content">
     <form action="https://workshop.castingwords.com/work/submit" method="post" id="workform" class="content" bind:this={workform}> 
      <input type="hidden" name="HTML-transcript" id="transcript" value={transcript}/>
      <input type="hidden" name="assignmentId" id="workformassignmentId" value={assignmentId} disabled={previewOnly}>
      <p>Comments on this job - this job is in beta and we need help improving it! </p>
      <textarea style="width: 100%; margin-bottom: 5px; height: 5em;" name="comments" id="comments" value="" disabled={previewOnly}></textarea>
    </form> 


    </div>
  </div>
  <footer class="card-footer">
    <a href="#"  on:click={e => showSubmit=false} class="card-footer-item">Cancel</a>
    <a href="#"   class="card-footer-item " style="border-radius:0"
    class:button={!previewOnly} 
    class:is-success={!previewOnly} 
    class:is-loading={submitting} 
    class:not-active={previewOnly} 
    class:is-disabled={previewOnly}
    on:click={submitJob}>Submit
    </a>
  </footer>
</div>


    </div>
    <button class="modal-close is-large" aria-label="close" on:click={e => showSubmit=false}></button>
</div>
